<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Permissions</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Permissions</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="this.hasPermission('CAN_CREATE_PERMISSION')"
                        :to="{name: 'create-permissions'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Permission
                    </router-link>
                </b-col>
                <b-col lg="6" class="mb-1rem">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                                v-on:keyup.enter="onFiltered"
                                @search="clearSearch"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="onFiltered"
                                    class="btn btn-primary"
                                >Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                @filtered="onFiltered"
                responsive
            >
            </b-table>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
            },
            api_error: "",
            permissions: [],
            fields: [
                { key: "id", label: "id" },
                { key: "name", label: "Permissions" }
            ],
            items: null,
            filter: null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_LIST_PERMISSIONS");
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        clearSearch (event) {
            if (event.target.value === "") {
                if (!this.filter) {
                    this.loader = true;
                    this.api_error = "";
                    this.getPermissions();
                }
            }
        },
        onFiltered() {
            this.permissions =[];
            this.urlBody.query = this.filter;
            this. getFilteredPermissions(this.urlBody);
        },
        onPageLoad: function (){
            this.permissions =[];
            var urlBody =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody);
            if(urlBody.query){
                this.getFilteredPermissions(urlBody);
            }else{
                this.getPermissions(urlBody);
            }
        },
        getFilteredPermissions: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = params;
            this.axios
                .get(this.$api.get_permissions, query)
                .then(response => {
                    const result = response.data.data[0];
                    this.items = result;
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(this.urlBody)
                        })
                        .catch(() => {});
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        getPermissions: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = params;
            this.axios
                .get(this.$api.get_permissions, query)
                .then(response => {
                    let id=1;
                    response.data.data.forEach(permission => {
                        this.permissions.push({
                            id:id,
                            name: permission
                        });
                        id++;
                    });
                    this.items = this.permissions;
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(this.urlBody)
                        })
                        .catch(() => {});
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>